import React, { useEffect, useState } from 'react';
import '../step.css'
import { getDatabase, ref, get, child } from 'firebase/database';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useParams } from 'react-router-dom';
import ShowLidim from '../menger/ShowLidim';
import '../loading.css';


export default function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showComponent, setShowComponent] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [loading, setLoad] = useState(false);
    const { name } = useParams();
    const nav = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const auth = getAuth();
        console.log("email " ,  localStorage.getItem(`email${name}`) , localStorage.getItem(`password${name}`));
        if (localStorage.getItem(`email${name}`) == email && localStorage.getItem(`password${name}`) == password) {
            setLoad(true);
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    localStorage.setItem(`emailLogin${name}`, email)
                    localStorage.setItem(`passwordLogin${name}`, password)
                    setShowComponent(true)
                    setError("מתחבר")
                    setLoad(false);
                })
        }
        else {
            setError("אחד או יותר מפרטי ההזדהות שגויים")
            setLoad(false);
            setTimeout(() => {
                setError("")
            }, 3000);
        }
    };

    useEffect(() => {
        if(localStorage.getItem("nameCPN") == name){
            setShowPage(true);
          }
        doApi()
        if (localStorage.getItem(`emailLogin${name}`) != null && localStorage.getItem(`passwordLogin${name}`) != null) {
            setEmail(localStorage.getItem(`emailLogin${name}`));
            setPassword(localStorage.getItem(`passwordLogin${name}`));
        }
    }, [])

    const doApi = () => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, `nir_mas/`)).then((snapshot) => {
          if (snapshot.hasChild(name)) {
            get(child(dbRef, `nir_mas/${name}/userWork`)).then((snapshot) => {
              if (snapshot.val() == "on") {
                localStorage.setItem('showPage', "true");
              } else {
                nav(`/kojwn23n`);
                setShowPage(true)
                localStorage.setItem('showPage', "false");
              }
            }).catch((error) => {
              console.error(error);
            });
          } else {
            nav(`/kojwn23n`);
            localStorage.setItem('showPage', "false");
          }
        }).catch((error) => {
          console.error(error);
        });
    }

    return (
        showPage ? <div>
            {!showComponent ? (
                <div className='backgroundLog anBack'>
                    <div className='container d-flex justify-content-center align-items-center  ' style={{ direction: 'rtl' }}>
                        <div className=" col-11 col-xl-5 mt-xl-4 broderLog p-3 mt-4 backcolorLoging ">
                            <h2 className='textLog animated-b text-white'>התחברות</h2>
                            <form onSubmit={handleLogin}>
                                <div className="form-group an">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="כתובת אימייל"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mt-2 an">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="סיסמה"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                {error && <div className="alert alert-danger">{error}</div>}
                                <button type="submit" className="btn btn-dark animated-button pe-2 ps-2 mt-3 border-3 border-success pe-5 ps-5">כניסה</button>
                            </form>

                        </div>
                    </div>
                    {loading &&
                        <div className="charging-circle-container">
                            <div className="charging-circle"></div>
                        </div>
                    }

                </div>
            ) : (
                <div>
                    <ShowLidim />
                </div>
            )}

        </div> : <div className="charging-circle-container">
            <div className="charging-circle"></div>
        </div>



    );
};
