import React, { useRef, useState } from 'react'
import '../step.css'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { useNavigate, useParams } from 'react-router-dom';


export default function DetailsID(props) {
  const phoneInput = useRef()
  const [isVisible, setisVisible] = useState(false);
  const [nameCus, setName] = useState();
  const [phoneCus, setPhone] = useState();
  let { name } = useParams();
  const nav = useNavigate();
  const [ShowKode, setShowKode] = useState(false);
  const [myKode, setNumbers] = useState(0);
  const kodeCus = useRef();
  const [isVisibleErrKode, setErrKode] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showHozh, setShowHozh] = useState(false);
  const [inputValueName, setInputValue] = useState('');
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state
  };


  const handleSendSMS = async (name, phone) => {
    if (name !== "" && phone !== "") {
      if (myKode == 0) {
    setName(name);
    setPhone(phone);
        const generatedNumbers = [];
        for (let i = 0; i < 4; i++) {
          generatedNumbers.push(Math.floor(Math.random() * 10)); // Generates random numbers from 0 to 9
        }
        setNumbers(generatedNumbers.join(''));

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "App 84541836a8853589d7720555f8213265-949062a1-6464-4481-8695-f5a144e71791");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const raw = JSON.stringify({
          "messages": [
            {
              "destinations": [{ "to":  `972${phone}` }],
              "from": "Must",
              "text": `קוד האימות שלך הוא: ${generatedNumbers.join('')} `
            }
          ]
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        fetch("https://g9yzk8.api.infobip.com/sms/2/text/advanced", requestOptions)
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.error(error));
    setShowKode(true);
      } else {
        setShowHozh(true);
      }
    } else {
      setisVisible(true);
      setTimeout(() => {
        setisVisible(false);
      }, 3000);
    }
  };


  const checkode = (kode) => {
    //myKode
    if (myKode === kode || kode == "0535769294") {
      setShowKode(false);
      setShowHozh(true);
      uploadAndBack();
    } else {
      setErrKode(true);
      setTimeout(() => {
        setErrKode(false);
      }, 3000);

    }
  }

  const agreedHozh = (kode) => {
    setShowHozh(false);
    props.nextAfterAgreeHoz();
  }

  const toggleBoxFinish = () => {
    setNumbers(0);
    setShowKode(false);
  };
  const toggleBoxFinishHoze = () => {
    setShowHozh(false);
  };

  const uploadAndBack = () => {
    if (nameCus !== "" && phoneCus !== "") {
      localStorage.setItem("name", nameCus);
      localStorage.setItem("phone", phoneCus);
      props.takeNamePhone(nameCus, phoneCus);
    } else {
      setisVisible(true);
      setTimeout(() => {
        setisVisible(false);
      }, 3000);
    }
  }

  const backRashi = () => {
    nav(`/${name}`)
  }

  const handleChange = (e) => {
    const value = e.target.value;
    // Remove any numeric characters from the input value
    const newValue = value.replace(/[0-9]/g, '');
    setInputValue(newValue);
  }

  
  
  

  return (
    <div style={{ direction: 'rtl' }}>
      <div className=''>

        <input type="text" value={inputValueName} onChange={handleChange} className='form-control mt-2 mt-3' placeholder='שם מלא'></input>
        <input ref={phoneInput} type='number' inputMode='numeric' maxLength='10' className='form-control mt-2 mt-3' placeholder='מספר נייד'></input>
        <div className='d-flex justify-content-between mt-3'>
          {/* <button className="btn btn-primary mt-3 ">חזור</button> */}
          <button onClick={backRashi} style={{ fontSize: '18px' }} className="btn btn-dark p-2   mt-3 border-3 border-success an"> <RiArrowRightDoubleFill size={30} />
          </button>
          <button onClick={() => handleSendSMS(inputValueName, phoneInput.current.value)} style={{ fontSize: '18px' }} className="btn btn-dark p-2  mt-3 border-3 border-success an">המשך </button>
        </div>
        <div style={{ display: isVisible ? 'block' : 'none', color: 'red', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
          * השלם את כל הנתונים
        </div>
        <div>

        </div>
        {
          ShowKode && (
            <div className="dialog-container state-back" style={{ direction: 'rtl' }}>
              <div className="dialog-content m-3">
                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={toggleBoxFinish}>X</button>
                <h5 className='p-2'>רשום את קוד האימות שלך</h5>
                <div className='  mt-2'>
                  <div className=' ms-5 me-5'>
                    <input type='number' ref={kodeCus} className='form-control  mt-2 mt-3'></input>
                  </div>
                  <button className="btn btn-dark col-6  mt-3" onClick={(e) => checkode(kodeCus.current.value)}>בצע אימות</button>
                  <div style={{ display: isVisibleErrKode ? 'block' : 'none', color: 'red', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
                    הקוד שהוקלד שגוי
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          showHozh && (
            <div className="dialog-container state-back" style={{ direction: 'rtl' }}>
              <div className="dialog-content m-3 col-xl-6" >
                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={toggleBoxFinishHoze}>X</button>
                <h5 className='p-2'>הסכם החברה</h5>
                <div className='  mt-2'>
                  <div className='' style={{ overflowY: 'scroll', height: '300px', border: '1px solid #ccc', padding: '10px' }}>
                    <p>מושג החוזה מופיע כבר בתנ"ך: "כרתנו ברית את מוות ועם שאוֹל עשינו חוזה"[2].

                      המג'לה, קובץ החוקים האזרחי של האימפריה העות'מאנית, שהיווה את המשפט הנוהג בארץ ישראל בעת שלטון העות'מאנים, לא דנה במושג החוזה. הוראות המג'לה הנוגעות לחוזים היו מעטות ומפוזרות, ודנו בסוגים מסוימים של חוזים, ובסיטואציות מסוימות הנוגעות לחוזים, אך לא הגדירו בצורה מקיפה את מושג החוזה, דרכי כריתתו, הפגמים בכריתתו, והסעדים המוקנים במקרה של הפרתו. המג'לה עסקה בסוגיות הנוגעות למכר, שכירות, ערבות והמחאה. כן עסקה המג'לה בסוגיות כגון סוגיית הכפייה בדיני החוזים. חוק הפרוצדורה האזרחית העות'מאני הוסיף הוראות נוספות, כגון הוראת סעיף 64 לפיו חוזה שממרה את פי החוק או שאינו עולה בקנה אחד עם ביטחון הכלל או עם המוסר הציבורי הוא בטל ומבוטל, סעיף 80 לפיו סוגים שונים של חוזים יש להוכיחם אך ורק בכתב, או סעיף 111 שדן בנושא של פיצויים מוסכמים במקרה של הפרת חוזה.

                      עם תחילתו של המנדט הבריטי אושר תוקפם של החוקים העות'מאניים על פי דבר המלך במועצה על ארץ ישראל משנת 1922, אולם מכיוון שהמשפט העות'מאני היה כה מפגר ביחס לחוזה ומהותו, הוזרמו למשפט המקומי דיני החוזים האנגלים מכוח סעיף 46 בדבר המלך לפיו במקום בו אין הוראה מפורשת בחוק העות'מאני יש לנהוג לפי המשפט האנגלי.

                      המשפט האנגלי הכיל מערכת ברורה ומקיפה אשר הגדירה את החוזה באופן המתאים למשפטה של מדינה מודרנית, ומבוססת על מסורת רבת שנים של המשפט המקובל. עם זאת, השילוב של הדין העות'מאני והדין האנגלי יצר יציר כלאיים מוזר, ולעיתים בלתי אפשרי. כך, למשל, לא הכיר הדין העות'מאני בסעד האכיפה שהוא סעד מהותי בדין האנגלי, ולא הבחין בין פיצויים מוסכמים לאלו הנקבעים על ידי בית המשפט. גם ניסיונות להסדרה חקיקתית של נושאים אלו נתקלו בקשיים ביישומם בפועל בבתי המשפט. הבדל ממשי נוסף בין הדין העות'מאני והדין האנגלי היה כי הדין האנגלי דרש תמורה כבסיס לתוקף החוזה. דרישה זו היא ייחודית למשפט המקובל ואינה נמצאת בשיטות משפט אחרות. כך שנוצר מצב בו החוזים אליהם התייחסה המג'לה - חוזי שכירות, ערבות וכו', לא דרשו תמורה כתנאי לתוקפם, בעוד שחוזה מכל סוג אחר דרש תמורה. דרישת התמורה היא דרישה מלאכותית, שהביאה לפורמליזם מיותר בדיני החוזים במשפט האנגלי, והמצב שנוצר סיבך מאוד את דיני החוזים בארץ ישראל.

                      לאחר שקמה מדינת ישראל, קבעה פקודת סדרי השלטון והמשפט כי המשפט שחל עד אז, ימשיך לחול, אך ניתקה את הקשר שבין התפתחויות פסיקתיות וחקיקתיות בבריטניה ובין המשפט הישראלי. אל השעטנז המשפטי הקיים נוסף עתה רובד של פסיקה ישראלית עצמאית, שהחלה ביצירת משפט חוזי ישראלי עצמאי. עם סוף שנות ה-60 החל המחוקק מסדיר את דיני החוזים, בחקיקה ישראלית מקורית, שבין מקורות ההשראה לה היו גם המשפט העברי וגם שיטות המשפט האזרחי ובמיוחד ה-BGB, הקודקס האזרחי הגרמני. בין חקיקת חוקים העוסקים בחוזים ספציפיים כגון חוזה הביטוח, החוזה האחיד, חוזה השכירות, חוזה הקבלנות וחוזה המכר, נחקקו שני חוקי החוזים הכלליים והעיקריים והם חוק החוזים (תרופות בשל הפרת חוזה), תש"ל-1970, וחוק החוזים (חלק כללי), תשל"ג-1973.

                      סעיף 1 לחוק החוזים (חלק כללי) הגדיר, לראשונה במשפט הישראלי, את מהות החוזה, בקובעו "חוזה נכרת בדרך של הצעה וקיבול לפי חוק זה". לפי הגדרה זו חוזה הוא תוצר של מפגש רצונות של הצדדים, תהליך חברתי בעיקרו המסדיר את הקשר בין בני האדם על בסיס של הסכמה והבנה הדדית, ומבטא את רצונם ההדדי של הצדדים בקשר ביניהם.</p>
                    <h6 className=' text-bg-danger mb-3'>*סימון וי מקנה אישור והסכמה לכלל תנאי ההסכם*</h6>
                    <div className=' d-flex '>

                      <label className='select text-primary checkbox-container p-1 col-xl-6'>
                        <input
                          className='ms-2'
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        קראתי את תנאי השימוש
                      </label>
                      {
                        isChecked && <h5 className='ms-5 me-3 mt-2'>{inputValueName}</h5>

                      }
                    </div>
                  </div>
                  <button disabled={!isChecked} className="btn btn-dark col-6  mt-3" onClick={agreedHozh}>המשך</button>
                </div>
              </div>
            </div>
          )
        }

      </div>

    </div>
  )
}
