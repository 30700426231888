import React, { useEffect, useState } from 'react'
import '../home.css'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { IoIosLogIn } from "react-icons/io";
import { MdContactPhone } from "react-icons/md";
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import { getDatabase, ref, set, get, child} from "firebase/database";

export default function AppEnterPage(props) {
  const { dynamicSegment } = useParams();
  const [name1, setName] = useState(false);
  const [phone, setPhone] = useState(0);
  const [remainingSecond, setremainingSecond] = useState(0);
  const nav = useNavigate();

  const nextPage = () => {
    nav(`calculator/`)
  }

  const nextPageShevah = () => {
    nav(`Shevah/`)
  }

  const nextPageLog = () => {
    nav(`login/`)
  }
  const callCompny = () => {
    window.location = `tel:${phone}`;
  }

  useEffect(() => {
    doApi();
    localStorage.setItem('showPage', "false");
    if (localStorage.getItem("nameCPN") == dynamicSegment) {
      setName(true);
    }
    localStorage.setItem('nameCPN', dynamicSegment);
    doApiMenger();
    doApiList();
    doApiBack();
    getPhoneFireBase()
  }, [remainingSecond]);

 
  const doApi = () => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `nir_mas/`)).then((snapshot) => {
      if (snapshot.hasChild(dynamicSegment)) {
        get(child(dbRef, `nir_mas/${dynamicSegment}/userWork`)).then((snapshot) => {
          if (snapshot.val() == "on") {
            localStorage.setItem('showPage', "true");
          } else {
            nav(`/kojwn23n`);
            localStorage.setItem('showPage', "false");
          }
        }).catch((error) => {
          console.error(error);
        });
      } else {
        nav(`/kojwn23n`);
        localStorage.setItem('showPage', "false");
      }
    }).catch((error) => {
      console.error(error);
    });
}



  //רשימת אנשים זכאים 
  const doApiList = async () => {
    const db = getDatabase();
    const name = dynamicSegment;
    const myListData = [];
    let detailsTest1 = [];
    try {
      const dbRef = ref(db, `/nir_mas/${name}/trueMas/`);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const firebaseData = snapshot.val();
        const dataArray = Object.entries(firebaseData);
        for (const [idKay, item] of dataArray) {
          const yearRef = ref(db, `/nir_mas/${name}/trueMas/${idKay}/year/`);
          const yearSnapshot = await get(yearRef);
          if (yearSnapshot.exists()) {
            const yearData = yearSnapshot.val();
            const yearArray = Object.entries(yearData);


            for (const [idKay2, itemYear] of Object.entries(yearData)) {
              const pdfRef = ref(db, `/nir_mas/${name}/trueMas/${idKay}/year/${idKay2}/pdf`);
              const pdfRefM = ref(db, `/nir_mas/${name}/trueMas/${idKay}/year/${idKay2}/pdfM`);
              const pdfSnapshot = await get(pdfRef);
              const pdfSnapshotM = await get(pdfRefM);
              if (pdfSnapshot.exists()) {
                const pdfData = pdfSnapshot.val();
                const pdfArray = Object.values(pdfData);
                if (pdfSnapshotM.exists()) {
                  const pdfDataM = pdfSnapshotM.val();
                  const pdfArrayM = Object.values(pdfDataM);
                  detailsTest1.push({
                    year: itemYear.year,
                    namem: itemYear.nameM,
                    sum: itemYear.sum,
                    sumM: itemYear.sumM,
                    sahclin: itemYear.sahclin,
                    sahclinM: itemYear.sahclinM,
                    ans: itemYear.ans,
                    tromaUrl: itemYear.TromaUrl == null ? "" : itemYear.TromaUrl,
                    lumiUrl: itemYear.lumUrl == null ? "" : itemYear.lumUrl,
                    lumiUrlM: itemYear.lumUrlM == null ? "" : itemYear.lumUrlM,
                    dataClac: itemYear.dataClac,
                    dataClacM: itemYear.dataClacM,
                    isChecked: false,
                    enterMesM: itemYear.enterMesM,
                    koptGemlM: itemYear.koptGemlM,
                    safeLifeM: itemYear.safeLifeM,
                    pdf: pdfArray,
                    pdfM: pdfArrayM
                  });
                }
                else {
                  detailsTest1.push({
                    year: itemYear.year,
                    sum: itemYear.sum,
                    sahclin: itemYear.sahclin,
                    ans: itemYear.ans,
                    tromaUrl: itemYear.TromaUrl == null ? "" : itemYear.TromaUrl,
                    lumiUrl: itemYear.lumUrl == null ? "" : itemYear.lumUrl,
                    lumiUrlM: itemYear.lumUrlM == null ? "" : itemYear.lumUrlM,
                    dataClac: itemYear.dataClac,
                    dataClacM: itemYear.dataClacM,
                    isChecked: false,
                    enterMesM: itemYear.enterMesM,
                    koptGemlM: itemYear.koptGemlM,
                    pdf: pdfArray
                  });
                }
              }
            }
            myListData.push({
              name: item.name,
              id: item.id,
              idkay: idKay,
              date: item.date,
              phone: item.phone,
              color: item.color,
              resPerson: item.resPerson,
              isChecked: false,
              details: detailsTest1
            });
            detailsTest1 = [];
          }
        }

        localStorage.removeItem(`myArray${name}`);
        localStorage.setItem(`myArray${name}`, JSON.stringify(myListData.reverse()));
      } else {
        localStorage.removeItem(`myArray${name}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //השאירו פרטים
  const doApiBack = async () => {
    const db = getDatabase();
    const myListData = [];
    const name = dynamicSegment;
    const dbRef = ref(db, `nir_mas/${name}/back/`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const firebaseData = snapshot.val();
      const dataArray = Object.entries(firebaseData);
      for (const [idKay, item] of dataArray) {
        myListData.push({
          name: item.name,
          phone: item.phone,
          date: item.date,
          isChecked: item.isChecked,
          type: item.type,
          idKay: idKay
        });
      }
      localStorage.setItem(`myArrayBack${name}`, JSON.stringify(myListData.reverse()));
      //setloading(false)
      //setBack(myListData.reverse());
    } else {
      localStorage.removeItem(`myArrayBack${name}`);

      //setloading(false)
    }
  };
  //מספר נייד ראשי 
  const getPhoneFireBase = async () => {
    const db = getDatabase();
    const dbRef = ref(db, `/nir_mas/${dynamicSegment}/details/phone`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const firebaseData = snapshot.val();
      setPhone(firebaseData)
    }
  };

    //רשימת מנהלים
    const doApiMenger = async () => {
      const db = getDatabase();
      const name = dynamicSegment;
      const dbRef = ref(db, `nir_mas/`);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const firebaseData = snapshot.val();
        const dataArray = Object.entries(firebaseData);
        for (const [idKay, item] of dataArray) {
          if (idKay === name) {
            localStorage.setItem(`email${name}`, item.details.email)
            localStorage.setItem(`password${name}`, item.details.password)
          }
        }
      }
    };



  return (
    <div >
      {
        <div className='background1 anBack'>
          <div className=" me-3 justify-content-end d-flex justify-content-between ">
            <div className=" ms-3 animated-b ">
              <button onClick={nextPageLog} style={{ fontWeight: 'bolder' }} className='btn btn-dark pe-2 ps-2 mt-3 border-3 border-success '>
                <IoIosLogIn size={25} />
              </button>
            </div >
          </div>
          <div className="  me-3 justify-content-end d-flex">
            <button onClick={nextPage} style={{ fontWeight: 'bolder', fontSize: '22px' }} className=' btn btn-dark animated-button mt-3 border-3 border-success'>לבדיקת החזר מס</button>
          </div>
          <div className=" me-3 justify-content-end d-flex ">
            <button onClick={nextPageShevah} style={{ fontWeight: 'bolder', fontSize: '22px' }} className='btn btn-dark p-2 mt-3 animated-button border-3 border-success'>לבדיקת החזר מס שבח</button>
          </div>
          <div className=" me-3 mt-3 d-flex  justify-content-center align-baseline text-w fixed-bottom p-3 an">
            <button onClick={() => callCompny()} style={{ fontWeight: 'bolder', fontSize: '23px' }} className='btn btn-dark pe-2 ps-2 mt-3 border-3 border-success'>
              צור קשר <MdContactPhone className='mb-1  align-bottom' size={23} />
            </button>
          </div >
        </div>
      }
    </div>

  )
}




