import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AppEnterPage from './HomePage/AppEnterPage'
import { initializeApp } from 'firebase/app';
import Login from './logIn/Login';
import './home.css'
import QuesPage from './chackMas/QuestionsPage';
import Empty from './HomePage/empty';


const firebaseConfig = {
  apiKey: "AIzaSyB07SwL3sEA5DM_Y3BdF6yQP64WEMegp8c",
  authDomain: "masnirnew.firebaseapp.com",
  databaseURL: "https://masnirnew-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "masnirnew",
  storageBucket: "masnirnew.appspot.com",
  messagingSenderId: "151161683048",
  appId: "1:151161683048:web:c9215bd3c1540d09418fdd"
};

const app = initializeApp(firebaseConfig);
//Nir

function App() {


  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/:dynamicSegment" element={<AppEnterPage />} />
          <Route path="/:name/login/" element={<Login/>} />
          <Route path="/:name/calculator/" element={<QuesPage />} />
          <Route path="/:name/Shevah/" element={<QuesPage />} />
          <Route path="/kojwn23n" element={<Empty/>} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
