import React, { useEffect, useState } from 'react'
import { getDatabase, ref, get, child, onChildAdded, onChildRemoved } from 'firebase/database';
import ItemBack from './itemBack';
import { IoIosPeople } from "react-icons/io";
import { useParams } from 'react-router-dom';
import _ from 'lodash';


export default function CallBack() {
    const [arrayDataBack, setBack] = useState([]);
    const [loading, setloading] = useState(true);
    let { name } = useParams();

 
    useEffect(() => {;
        getList();
         lisnDBBack();
      }, []);

      const lisnDBBack = () => {
        const dbRef = ref(getDatabase());
        const itemsRef = child(dbRef, `/nir_mas/${name}/back/`);
        const storedItems = JSON.parse(localStorage.getItem(`myArrayBack${name}`)) || [];
        const handleChildAdded = (snapshot) => {
            const newItem = snapshot.val();
            const itemId = snapshot.key;

            // Check if the item already exists in the list
            const isItemExist = storedItems.some(item => item.idKay === itemId);
            if (!isItemExist) {
                const updatedItems = [
                    {
                        name: newItem.name,
                        phone: newItem.phone,
                        date: newItem.date,
                        isChecked: false,
                        type: newItem.type,
                        idKay: itemId
                    },
                    ...storedItems
                ];
                setBack(updatedItems);
                localStorage.setItem(`myArrayBack${name}`, JSON.stringify(updatedItems));
                setloading(false)
            }
        };

        const handleChildRemoved = (snapshot) => {
            const newItem = snapshot.val();
            const itemId = snapshot.key;
            const updatedItems = storedItems.filter((item) => item.idKay  !==  itemId )
            setBack(updatedItems);
            localStorage.setItem(`myArrayBack${name}`, JSON.stringify(updatedItems));
        }
        onChildAdded(itemsRef, handleChildAdded);
        onChildRemoved(itemsRef , handleChildRemoved);
    }

    const getList = () => {
        const storedArray = JSON.parse(localStorage.getItem(`myArrayBack${name}`)) || '';
        if(storedArray){
            setBack(storedArray);
            setloading(false)
        }else{
            setloading(false)
        }
    }


    const updateUser = (id , chek) => {
        let storedArray = [];
        const localStorageArray = JSON.parse(localStorage.getItem(`myArrayBack${name}`)) || [];
        storedArray = localStorageArray.map((item, index) => {
            if (item.idKay == id) {
                localStorageArray[index].isChecked = chek;
                localStorage.setItem(`myArrayBack${name}`, JSON.stringify(localStorageArray));
                setBack(localStorageArray);
            }
        })
    }

    const removeItem = (idRemove) => {
        let storedArray = [];
        storedArray = arrayDataBack.filter((item, index) => item.idKay !== idRemove)
        setBack(storedArray);
    };



    const doApi = async () => {
        const db = getDatabase();
        const myListData = [];
        const dbRef = ref(db, `nir_mas/${name}/back/`);
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
            const firebaseData = snapshot.val();
            const dataArray = Object.entries(firebaseData);
            for (const [idKay, item] of dataArray) {
                myListData.push({
                    name: item.name,
                    phone: item.phone,
                    date: item.date,
                    idKay: idKay
                });
            }
            setloading(false)
            setBack(myListData.reverse());
        } else {
            setloading(false)
        }
    }

    return (
        <div className='mb-3'>
            {loading &&
                <div className="charging-circle-container">
                    <div className="charging-circle"></div>
                </div>
            }
            {arrayDataBack != undefined && arrayDataBack.map((person, index) => (
                <ItemBack updateUser={updateUser} key={index} removeItem={removeItem} person={person} />
            ))}
            {
                ((arrayDataBack.length == 0 && loading == false) && <div className='mt-3 p-4'>
                    <div className=' d-flex justify-content-center mb-2'>
                        <IoIosPeople className=' text-white' size={45} />
                    </div>
                    <div className=' d-flex justify-content-center'>
                        <h2 className=' text-white'>אין כרגע לקוחות</h2>
                    </div>
                </div>)
            }
        </div>
    )
}
