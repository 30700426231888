import React from 'react';

export default function Empty() {
  const divStyle = {
    visibility: 'hidden',
    position: 'absolute', // Add positioning styles
    right: 0,
    left: '10px',
    bottom: '10px',
    filter: 'hue-rotate(300deg)'
  };

  return (
    <div>
      <div className="charging-circle-container">
        <div className="charging-circle"></div>
      </div>
      <div
        id="NagishLiTriggerErr"
        style={divStyle}
      >
      </div>
    </div>
  );
}
